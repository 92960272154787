import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import Yoast from 'components/globals/yoast';
import Link from 'components/link/link';

import './styles/sitemap.scss';

export default function Sitemap() {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(filter: { slug: { eq: "sitemap" } }) {
        edges {
          node {
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node;
  
  return (
    <Layout>
      <Yoast { ...pageContext.yoast_head_json } />
      <section className="sitemap-page-container section" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <div className="container box">
          <h1 className="title has-text-centered">Site Map</h1>
          <div className="columns">
            <div className="column">
              <h2>Products</h2>
              <ul className="unstyled-list">
                <li>
                  <Link to="/features">Employee Scheduling</Link>
                </li>
                <li>
                  <Link to="/features/time-clock">Employee Time Clock</Link>
                </li>
                <li>
                  <Link to="/features/team-messaging">Team Messaging</Link>
                </li>
              </ul>
              <h2>Industries Served</h2>
              <ul className="unstyled-list">
                <li>
                  <Link to="/industries">Industries Served</Link>
                </li>
                <li>
                  <Link to="/industries/call-centers-customer-support">
                    Call Centers / Customer Support
                  </Link>
                </li>
                <li>
                  <Link to="/industries/caregivers-assisted-living">
                    Caregivers / Assisted Living
                  </Link>
                </li>
                <li>
                  <Link to="/industries/catering-events">
                    {' '}
                    Catering / Events
                  </Link>
                </li>
                <li>
                  <Link to="/industries/colleges-universities-schools">
                    Colleges / Universities / Schools
                  </Link>
                </li>
                <li>
                  <Link to="/industries/entertainment-seasonal">
                    Entertainment / Seasonal
                  </Link>
                </li>
                <li>
                  <Link to="/industries/emergency-law-enforcement-security">
                    Emergency / Law Enforcement / Security
                  </Link>
                </li>
                <li>
                  <Link to="/industries/healthcare-medical">
                    Healthcare / Medical
                  </Link>
                </li>
                <li>
                  <Link to="/industries/hotels-resorts"> Hotels / Resorts</Link>
                </li>
                <li>
                  <Link to="/industries/professional-services">
                    Professional Services
                  </Link>
                </li>
                <li>
                  <Link to="/industries/restaurants-cafes-bars-coffee-shops">
                    Food Service
                  </Link>
                </li>
                <li>
                  <Link to="/industries/retail">Retail</Link>
                </li>
                <li>
                  <Link to="/industries/technology-software">
                    Technology / Software
                  </Link>
                </li>
                <li>
                  <Link to="/industries/volunteers-non-profit">
                    Volunteers / Non-Profit
                  </Link>
                </li>
              </ul>
            </div>
            <div className="column">
              <h2>About</h2>
              <ul className="unstyled-list">
                <li>
                  <Link to="/integrations">Integrations</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms">Terms &amp; Conditions</Link>
                </li>
                <li>
                  <Link to="/newsroom">Press Room</Link>
                </li>
              </ul>
              <h2>Pricing &amp; Signup</h2>
              <ul className="unstyled-list">
                <li>
                  <Link to="/pricing">Pricing</Link>
                </li>
                <li>
                  <Link to="/signup">Sign Up</Link>
                </li>
                <li>
                  <Link to="/enterprise">Enterprise</Link>
                </li>
              </ul>
            </div>
            <div className="column">
              <h2>Resources</h2>
              <ul className="unstyled-list">
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/blog/employee-scheduling-excel-template">
                    Scheduling Excel Template
                  </Link>
                </li>
                <li>
                  <Link to="/blog/free-employee-timesheet-template-for-excel">
                    Timesheet Template for Excel
                  </Link>
                </li>
                <li>
                  <Link to="/tools/employee-of-the-month-certificate">
                    Employee of the Month Certificate
                  </Link>
                </li>
                <li>
                  <Link to="/library/pocket-book-of-management-hacks">
                    Pocket Book of Management Hacks
                  </Link>
                </li>
                <li>
                  <Link to="/library/managing-millennials-ebook">
                    Epic Guide To Managing Millennials In The Workplace
                  </Link>
                </li>
                <li>
                  <Link to="/library/people-management-handbook">
                    People Management Handbook
                  </Link>
                </li>
                <li>
                  <Link to="/library/millennials-are-all-grown-up">
                    4 New Things You Need to Know About Managing Millennials
                  </Link>
                </li>
                <li>
                  <Link to="/library/the-guide-to-managing-your-seasonal-workforce">
                    The Ultimate Guide to Managing your Seasonal Workforce
                  </Link>
                </li>
                <li>
                  <Link to="/library/the-first-time-managers-handbook">
                    The First Time Managers Handbooks
                  </Link>
                </li>
              </ul>
              <h2>Support</h2>
              <ul className="unstyled-list">
                <li>
                  <Link to="https://help.wheniwork.com/submit-a-ticket">
                    Submit A Ticket
                  </Link>
                </li>
                <li>
                  <Link to="https://help.wheniwork.com/education">
                    Watch a Webinar
                  </Link>
                </li>
                <li>
                  <Link to="https://help.wheniwork.com">Help Center</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
